import dashboardStyle from "../styles/Dashboard.module.css";
import fantasmaopinion from "../images/fantasmaOpinion.png";
import fantasmitaAsesor from "../images/fantasmita-asesor.png";
import pregunta from "../images/pregunta.png";
import { useState, useEffect } from "react";
import { useDataContext } from "../contexts/AuthContext";
import Modal1 from "./Modal1";
import Modal2 from "./Modal2";
import Modal3 from "./Modal3";
import ModalInfoPago from "../components/ModalIfoPago";
import Flecha from "../images/flecha-magenta.png";
import bigRayo from "../images/bigRayo.png";
import { useNavigate } from "react-router-dom";
import { useDebtsContext } from "../contexts/FeeContext";
import { useExtraCostContext } from "../contexts/extraCostContext";
import { useLowerQuantityContext } from "../contexts/LowerQuantityContext";
import { getAgentInfo } from "../services/api";
import { getItems } from "../services/api";
import { getPaymentInformation } from "../services/api";
import { getFeeInfo } from "../services/api";
import { hasCustomerItemsSelected } from "../services/api";
import { fetchFeesWithInterest, getOrderItemsExtras } from "../services/api";
import logoPng from "../images/Frame.png";
import ModalConfirmarItems from "./ModalConfirmarItems";
import ModalExtraPrenda from "./ModalExtraPrenda";
import ModalExtraPago from "./ModalExtraPago";
import Swal from "sweetalert2";

function Dashboard() {
  const navigate = useNavigate();
  const { contextData } = useDataContext();
  const { userDebts } = useDebtsContext();
  const { extraCost } = useExtraCostContext();
  const { lowerQuantity } = useLowerQuantityContext();
  const [items, setItems] = useState([]);
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [showModal1, setShowModal1] = useState(false);
  const [showModalpagoExtra, setShowModalPagoExtra] = useState(false);

  const [showModal2, setShowModal2] = useState(false);
  const [showModalPrenda, setShowModalPrenda] = useState(false);
  const [prendaExtra, setPrendaExtra] = useState(false);
  const [showModalConfirmar, setShowModalConfirmar] = useState(false);

  const [showModalInfoPago, setShowModalInfoPago] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(true);
  const [paymentInformation, setPaymentInformation] = useState([]);
  const [paid, setPaid] = useState("");
  const [total, setTotal] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [cuotaInfo, setCuotaInfo] = useState("");
  const [progress, setProgress] = useState(0);
  const [feesWithInterest, setFeesWithInterest] = useState([]);
  const [hasPaymentsRecords, setHasPendingRecords] = useState(false);
  const [hasPaymentsPending, setHasPendingPayments] = useState(false);
  //logica boton aceptar y para deshabilitar boton cambiar metodo pago
  const [isBotonHabilitado, setIsBotonHabilitado] = useState(true);
  const [loading, setLoading] = useState(true);
  const [PaymentCount, setPaymentCount] = useState(0);
  const [extras, setExtras] = useState([]);
  const [vengodelModal1, setVengoDelModal1] = useState(false);

  useEffect(() => {
    if (!hasPaymentsRecords) {
      return;
    }
    if (hasPaymentsRecords === false) {
      setIsBotonHabilitado(true);
    } else {
      setIsBotonHabilitado(false);
    }
  }, [hasPaymentsRecords]);

  useEffect(() => {
    console.log("constexData", contextData);
  }, [contextData]);
  useEffect(() => {
    console.log("user debst", userDebts);
  }, [userDebts]);
  // Calcula el progreso en función del pago de las cuotas
  useEffect(() => {
    if (!paid || !total) {
      return;
    }
    if (parseFloat(total) > 0) {
      const percentagePaid = (parseFloat(paid) / parseFloat(total)) * 100;
      setProgress(percentagePaid); // Actualiza el progreso
    }
  }, [paid, total]);

  const calcularPend = (t, p) => {
    if (t - p < 100) {
      p = t;
    }
    return t - p;
  };

  const formularios = () => {
    navigate("/mcb/informar");
  };

  const accountsSummary = () => {
    navigate("/mcb/accountsSummary");
  };

  const currentDate = new Date();
  let hasExpiredDebts = false;

  const userDebtsWithStyles = feesWithInterest.map((cuota) => {
    const expirationDate = new Date(cuota.expiration_date);

    const formattedDate = expirationDate.toLocaleDateString("es-ES", {
      timeZone: "UTC",
    });
    console.log(formattedDate);

    let className = dashboardStyle.dashboardDebtsTableFont;

    if (currentDate > expirationDate) {
      className = dashboardStyle.dashboardDebtsTableFontExpired;
      hasExpiredDebts = true;
    } else if (
      expirationDate.getMonth() === currentDate.getMonth() &&
      expirationDate.getFullYear() === currentDate.getFullYear()
    ) {
      className = dashboardStyle.dashboardDebtsTableFontNotExpired;
    }

    return { ...cuota, className, formatted_expiration_date: formattedDate };
  });

  useEffect(() => {
    if (
      !contextData ||
      !contextData.customer ||
      !contextData.customer.lead_id
    ) {
      return;
    }
    getItems(contextData.customer.lead_id)
      .then((data) => {
        console.log("Respuesta del servidor:", data.productModels);
        setItems(data.productModels);
      })
      .catch((error) => {
        console.log("Error en la solicitud de items", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [contextData.customer.lead_id]);

  const [hasExtraCharges, setHasExtraCharges] = useState(false);
  useEffect(() => {
    // Verificar si contextData.customer.id tiene un valor válido
    if (!contextData || !contextData.customer || !contextData.customer.id) {
      console.log(
        "ID de cliente no válido. No se puede obtener información de pago."
      );
      return;
    }

    // Hacer la petición al backend para obtener las cuotas actualizadas con intereses
    getPaymentInformation(contextData.customer.id)
      .then(async (data) => {
        console.log("Datos de pago:", data);
        setPaymentInformation(data);
        setPaymentMethod(data.payment_method);
        setPaid(data.paid);
        setTotal(data.total);
        setHasPendingRecords(data.hasPaymentsRecords);
        setPaymentCount(data.PaymentCount);
        setHasPendingPayments(data.hasPaymentsPending);

        const ExtraCharges = data.extraDesign > 0 || data.extraQuantity > 0;

        // Use the 'hasExtraCharges' variable for further logic
        console.log("Hay cargos extras:", hasExtraCharges);
        if (ExtraCharges) {
          setHasExtraCharges(true);
        }
      })
      .catch((error) => {
        console.log("Error en la solicitud de información de pago", error);
      });

    const fetchFees = async () => {
      try {
        const updatedFees = await fetchFeesWithInterest(userDebts);
        setFeesWithInterest(updatedFees);
      } catch (error) {
        console.error("Error al calcular intereses:", error);
      }
    };

    // Llama a la función para obtener las cuotas con intereses al montar el componente
    if (userDebts.length > 0) {
      fetchFees();
    }
  }, [contextData, userDebts]);

  useEffect(() => {
    if (!contextData || !contextData.customer || !contextData.customer.id) {
      return;
    }
    const hasSelectedPaymentMethod = async () => {
      try {
        const hasItemsSelected = await hasCustomerItemsSelected(
          contextData.customer.id
        );
        const { hasItemSelected } = hasItemsSelected;
        setPrendaExtra(hasItemSelected);
        console.log("items", hasItemsSelected);
        if (hasItemSelected === true) {
          setShowModal1(false);
        } else {
          setShowModal1(true);
        }
      } catch (error) {
        console.error(
          "Error al verificar si el cliente tiene ítems seleccionados:",
          error
        );
      }
    };

    hasSelectedPaymentMethod();
  }, [contextData, contextData.customer.id]);

  useEffect(() => {
    const fetchExtras = async () => {
      try {
        const data = await getOrderItemsExtras(contextData.customer.id);
        setExtras(data);
        setLoading(false);
        console.log("los extras son:", data);
      } catch (error) {
        console.error(
          "Error al retornar los extras de los order_items:",
          error
        );
        setLoading(false);
      }
    };

    fetchExtras();
  }, [contextData.customer.id, userDebts]);

  const calculateTotal = (item) => {
    const { quantity, extraQuantity, extraDesign } = item;
    const totalExtraQuantity = (extraQuantity ?? 0) * quantity;
    const totalExtraDesign = (extraDesign ?? 0) * quantity;
    return totalExtraQuantity + totalExtraDesign;
  };

  //lica para el div pedido y asesor desplegable
  const [showAsesor, setShowAsesor] = useState(false);
  const [agentFirstName, setAgentFirstName] = useState("");
  const [agentSurname, setAgentSurname] = useState("");
  const [agentPhone, setAgentPhone] = useState("");
  const [agentEmail, setAgentEmail] = useState("");
  //logica de desplegable asesor
  const toggleAsesor = () => {
    setShowAsesor(!showAsesor);
  };
  const handleAsesorTouchStart = () => {
    setShowAsesor(true);
  };
  const handleAsesorTouchEnd = () => {
    setShowAsesor(false);
  };
  useEffect(() => {
    if (
      !contextData ||
      !contextData.customer ||
      !contextData.customer.lead_id
    ) {
      return;
    }
    console.log("agentinfo:", contextData.customer.lead_id);
    getAgentInfo(contextData.customer.lead_id)
      .then((data) => {
        setAgentFirstName(data.first_name);
        setAgentSurname(data.last_name);
        setAgentPhone(data.agentPhone);
        setAgentEmail(data.email);
      })
      .catch((error) => console.log(error));
  }, [contextData.customer.lead_id]);

  //logica del contenido desplegable
  const handleShowContent = () => {
    console.log({ selectedQuantities });
    setIsContentVisible(true);
  };

  const handleHideContent = () => {
    setIsContentVisible(true);
  };
  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };
  //logica para la fecha de la tabla

  const fecha = new Date();
  const dia = fecha.getDate();
  const mes = fecha.getMonth() + 1;
  const anio = fecha.getFullYear();
  const fechaformateada = `${dia}/${mes}/${anio}`;
  const nuevaFecha = new Date();
  nuevaFecha.setDate(fecha.getDate() + 30);

  const diaNuevaFecha = nuevaFecha.getDate();
  const mesNuevaFecha = nuevaFecha.getMonth() + 1;
  const anioNuevaFecha = nuevaFecha.getFullYear();
  const nuevaFechaFormateada = `${diaNuevaFecha}/${mesNuevaFecha}/${anioNuevaFecha}`;

  // logica para el boton siguiente del modal
  const handleButtonClick = () => {
    setShowModal2(true);
  };
  const handleCerrarModal2 = () => {
    setShowModal1(false);
    setVengoDelModal1(false);
  };
  const handleButtonClick2 = () => {
    setShowModal1(true);
  };
  const handleButtonClickPrenda = () => {
    setShowModalPrenda(true);
  };
  const handleAtrasClick = () => {
    setShowModal1(true);
    setShowModal2(false);
  };
  const handleNextClick = () => {
 
      setShowModal1(false);
      setShowModal2(true);
    
  };
  const handleFormularioEnviado = () => {
    setIsBotonHabilitado(false);
  };

  const handleAcceptClick = () => {
    setShowModal2(false);
    setShowModalConfirmar(true);
  };
  const handleAceptar = () => {
    setShowModalConfirmar(false);
  };
  const handleCancelar = () => {
    setShowModalConfirmar(false);
    setShowModal2(true);
  };
  const handleCerrarPrenda = () => {
    setShowModalPrenda(false);
  };
  const handleSiguinetePrenda = () => {
    // setShowModalPagoExtra(true);
    setShowModalPagoExtra(false);
  };
  const handleCerrarPagoExtra = () => {
    setShowModalPagoExtra(false);
  };
  const handleBackClickPagoExtra = () => {
    setShowModalPrenda(true);
    setShowModalPagoExtra(false);
  };

  //logica boton atras del modal2
  const handleBackClick = () => {
    setShowModal2(false);
     setShowModal1(true);
  };
  //logica ara el ModalInfoPago
  const toggleModalInfo = () => {
    setShowModalInfoPago(!showModalInfoPago);
  };

  //logica para los detalles de cuotas rojas
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const handleDetailMouseEnter = async (id) => {
    setIsDetailVisible(true);
    try {
      const data = await getFeeInfo(id);
      setCuotaInfo(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDetailMouseLeave = () => {
    setIsDetailVisible(false);
    setCuotaInfo(null); // Limpiar la información cuando el mouse sale del botón
  };
  //logica de costos extras
  const [mostrarExtras, setMostrarExtras] = useState(false);
  const handleMouseEnter = () => {
    setMostrarExtras(true);
  };

  const handleMouseLeave = () => {
    setMostrarExtras(false);  
  };
  const handleTouchStart = () => {
    setMostrarExtras(true);
  };
  const handleTouchEnd = () => {
    setMostrarExtras(false);
  };
  //logica para el modal3
  const [isModal3Open, setIsModal3Open] = useState(false);
  const toggleModal3 = () => {
    setIsModal3Open(!isModal3Open);
  };

  const capitalizeFirstLetter = (string) => {
    if (typeof string !== "string" || string.length === 0) {
      return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      {loading ? ( // Mostrar indicador de carga mientras se verifica
        <div className="contcargando">
          <img src={logoPng} alt="littleBlueLogo" className="imgcargando" />
          <h2 className="text-muted">CARGANDO...</h2>
          <div className="loader"></div>
        </div>
      ) : (
        <div className={dashboardStyle.bigcontainer}>
          {showModal1 && (
            <Modal1
              onNextClick={handleNextClick}
              closeModal={handleCerrarModal2}
              setPaymentMethod={setPaymentMethod}
              paymentMethod={paymentMethod}
              vengodelModal1={setVengoDelModal1}
            />
          )}
          {showModalPrenda && (
            <ModalExtraPrenda
              onNextClick={handleCerrarPrenda}
              onSiguiente={handleSiguinetePrenda}
              paymentMethod={paymentMethod}
            />
          )}
          {showModalpagoExtra && (
            <ModalExtraPago
              onNextClick={handleCerrarPagoExtra}
              onBackClick={handleBackClickPagoExtra}
            />
          )}
          {showModal2 && (
            <Modal2
              onAcceptClick={handleAcceptClick}
              onBackClick={handleBackClick}
              setPaymentInformation={setPaymentInformation}
              setPaymentMethod={setPaymentMethod}
              setTotal={setTotal}
              setPaid={setPaid}
              vengodelModal1={vengodelModal1}
              vengodelModal2={setVengoDelModal1}
              handleAtrasClick={handleAtrasClick}
            />
          )}
          {showModalConfirmar && (
            <ModalConfirmarItems
              handleAceptar={handleAceptar}
              handleCancelar={handleCancelar}
              setPaymentMethod={setPaymentMethod}
              userDebtsWithStyles={userDebtsWithStyles}
            />
          )}

          {/* elementos del mainmenu que pasan al dashboard*/}
          <div className={dashboardStyle.pedidoR}>
            <h3 className={dashboardStyle.h3}>
              {" "}
              NUM DE PEDIDO{" "}
              <span className={dashboardStyle.spanpedidoR}>
                {contextData.customer.username}{" "}
              </span>{" "}
            </h3>
            <section className={dashboardStyle.representanteR}>
              <div className={dashboardStyle.asesorR}>
                <img
                  src={fantasmitaAsesor}
                  className={dashboardStyle.fantasmita}
                  alt="Fantasmita-Asesor"
                  onClick={toggleAsesor}
                  onMouseEnter={handleAsesorTouchStart}
                  onMouseLeave={handleAsesorTouchEnd}
                />
              </div>
              {showAsesor && (
                <div className={dashboardStyle.datosAsesorContainerR}>
                  <p>LUN - VIE 9-13 /14 - 18 hs</p>
                  <section>
                    <i className="bi bi-emoji-smile"></i>{" "}
                    <b>
                      {agentFirstName} {agentSurname}
                    </b>
                  </section>
                  <section>
                    <i className="bi bi-telephone-fill"></i> 396000101
                  </section>
                  <section>
                    <a
                      href={`https://wa.me/${agentPhone}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="bi bi-whatsapp"></i> {agentPhone}
                    </a>
                  </section>
                  <section>
                    <i className="bi bi-envelope-fill"></i> {agentEmail}
                  </section>
                </div>
              )}
            </section>
          </div>

          <div className={dashboardStyle.saludoResponsive}>
            <h1 className={dashboardStyle.saludoR}>
              <span className={dashboardStyle.saludoS}> Hola </span>
              {capitalizeFirstLetter(contextData.customer.first_name)}!
            </h1>
          </div>

          <div>
            <p className={dashboardStyle.pend}>
              SALDO PENDIENTE: ${calcularPend(total, paid)}
            </p>
          </div>

          <div className={dashboardStyle.progressBarContainer}>
            <progress
              className={dashboardStyle.progressBar}
              value={progress}
              max={100}
            >
              <p>{progress}%</p>
              <p>{progress}%</p>
            </progress>
            <div
              className={dashboardStyle.progressImage}
              style={{ left: `${progress}%` }}
            >
              <img src={bigRayo} alt="Rayo" />
            </div>
          </div>
          <section className={dashboardStyle.estadocuentacontainer}>
            <p className={dashboardStyle.paid}>
              ABONADO
              <span className={dashboardStyle.spanPaid}>
                {" "}
                {""}${total - paid < 100 ? total : paid}
              </span>
            </p>

            <p className={dashboardStyle.paid}>
              TOTAL
              <span className={dashboardStyle.spanPaid}> ${total} </span>
            </p>
          </section>
          {console.log("hasPaymentsrecords:", hasPaymentsRecords)}
          {console.log(
            "Hay más de un registro en la tabla payments. En total hay:",
            PaymentCount
          )}
          {hasPaymentsPending ? (
            <div className={dashboardStyle.pendingTextContainer}>
              <p className={dashboardStyle.pendingText}>
                Tiene pagos pendientes de aprobación
              </p>
            </div>
          ) : null}
          <div className={dashboardStyle.toggles}>
            <div className={dashboardStyle.inlineElements}>
              {/*logica de detalle
          <p className={dashboardStyle.detalle}>DETALLES</p>
          <img
            src={Flecha}
            alt={isContentVisible ? "flechaArriba" : "flechita roja"}
            onTouchStart={handleShowContent}
            onTouchEnd={handleHideContent}
            onMouseEnter={handleShowContent}
            onMouseLeave={handleHideContent}
            className={`${dashboardStyle.flecha} ${
              isContentVisible && dashboardStyle.active
            }`}
          />*/}
              {/* <div className={dashboardStyle.detalle} onClick={toggleContent}> */}
              <div className={dashboardStyle.detalle}>
                DETALLES
                {/* <img
                  src={Flecha}
                  alt={isContentVisible ? "flechaArriba" : "flechita roja"}
                  onTouchStart={toggleContent}
                  onTouchEnd={toggleContent}
                  onMouseEnter={handleShowContent}
                  onMouseLeave={handleHideContent}
                  className={`${dashboardStyle.flecha} ${
                    isContentVisible && dashboardStyle.active
                  }`}
                /> */}
              </div>

              <button
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                className={dashboardStyle.botonMagenta}
              >
                Adicionales
              </button>
            </div>

            <section className={dashboardStyle.modalidadcontainer}>
              <p className={dashboardStyle.modality}>
                Modalidad de pago:
                <span className={dashboardStyle.span}>
                  {paymentMethod.toLocaleUpperCase()}
                </span>
              </p>
              <div className={dashboardStyle.containerbotones}>
                <button
                  className={`${dashboardStyle.botongris} ${
                    isBotonHabilitado
                      ? dashboardStyle.botonActivado
                      : dashboardStyle.botonDesactivado
                  }`}
                  onClick={handleButtonClick}
                  disabled={!isBotonHabilitado}
                >
                  CAMBIAR METODO DE PAGO
                </button>
                {/* <button
                  className={` ${dashboardStyle.botongris} ${
                    isBotonHabilitado
                      ? dashboardStyle.botonActivado
                      : dashboardStyle.botonDesactivado
                  }`}
                  disabled={!isBotonHabilitado}
                  onClick={handleButtonClick2}
                >
                  EDITAR PRENDAS
                </button> */}
                <button
                  className={` ${dashboardStyle.botongris} ${dashboardStyle.botonActivado}`}
                  onClick={handleButtonClickPrenda}
                  disabled={isBotonHabilitado}
                >
                  PRENDA EXTRA
                </button>
              </div>
            </section>

            {isContentVisible && (
              <div className={dashboardStyle.contentWrapper}>
                {/* Verificamos que paymentInformation sea un objeto y si existe paymentInformation.items */}
                {paymentInformation && paymentInformation.items && (
                  <div className={dashboardStyle.contenidoDesplegado}>
                    <ul className={dashboardStyle.lista}>
                      {paymentInformation.items.map((item, index) => (
                        <li key={index} className={dashboardStyle.items}>
                          <h4>
                            {item.name}{" "}
                            <span className={dashboardStyle.blackSpan}>
                              x {item.quantity}
                            </span>
                          </h4>
                          <h5>${item.price}c/u</h5>
                        </li>
                      ))}
                    </ul>
                    <div className={dashboardStyle.financio}>
                      <p>Financiamiento:</p>
                      {paymentInformation.price_surcharge !== undefined &&
                      paymentInformation.price_discount !== undefined ? (
                        <>
                          <strong>
                            {paymentInformation.price_surcharge > 0 ? (
                              <>{`+$${paymentInformation.price_surcharge}`}</>
                            ) : paymentInformation.price_discount > 0 ? (
                              <>{`-$${paymentInformation.price_discount} OFF`}</>
                            ) : (
                              <>{`$0`}</>
                            )}
                          </strong>
                        </>
                      ) : (
                        <>
                          <strong>{`$0`}</strong>
                        </>
                      )}
                      <p>
                        │ Adicional por minoría:{" "}
                        <strong>
                          $
                          {paymentInformation.extraQuantity !== null
                            ? paymentInformation.extraQuantity
                            : "0"}
                        </strong>
                      </p>
                      <p>
                        │ Cambio de diseño:{" "}
                        <strong>
                          $
                          {paymentInformation.extraDesign !== null
                            ? paymentInformation.extraDesign
                            : "0"}
                        </strong>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/*fin logica detalle*/}
            {/*logica extras*/}
            {mostrarExtras && (
              <div className={dashboardStyle.extraContainer}>
                {extras.map((item, index) => (
                  <ul key={index}>
                    <p>
                      <strong>{item.name}:</strong>
                    </p>
                    <p>Cantidad elegida: x{item.quantity}</p>
                    <p>Extra por minoría: ${item.extraQuantity ?? 0}</p>
                    <p>Extra por diseño: ${item.extraDesign ?? 0}</p>
                    <p>
                      <strong>Total Extras:</strong> ${calculateTotal(item)}
                    </p>
                  </ul>
                ))}
              </div>
            )}
            {/*fin logica extras*/}
          </div>
          <table className={dashboardStyle.table}>
            <thead>
              <tr className={dashboardStyle.th}>
                <th className={dashboardStyle.th}>CUOTA</th>
                <th className={dashboardStyle.th}>VENCIMIENTO</th>
                <th className={dashboardStyle.th}>VALOR</th>
              </tr>
            </thead>
            <tbody>
              {userDebtsWithStyles
                .filter((cuota) => cuota.is_extra !== 1 && cuota.is_extra !== 2)
                .sort((a, b) => a.number - b.number)
                .map((cuota) => (
                  <tr key={cuota.id}>
                    <td className={cuota.className}>{cuota.number}° CUOTA</td>
                    <td className={cuota.className}>
                      {cuota.formatted_expiration_date}
                    </td>
                    <td className={cuota.className}>
                      ${cuota.pending}
                      {cuota.className ===
                        dashboardStyle.dashboardDebtsTableFontExpired && (
                        <button
                          type="button"
                          onMouseEnter={() => handleDetailMouseEnter(cuota.id)}
                          onMouseLeave={handleDetailMouseLeave}
                          className={dashboardStyle.questionMark}
                        >
                          <img
                            src={pregunta}
                            alt="Descripción"
                            className={dashboardStyle.questionImage}
                          />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              {userDebtsWithStyles
                .filter((cuota) => cuota.is_extra === 1 && hasExtraCharges)
                .map((cuota) => (
                  <tr key={cuota.id} className="text-center">
                    <td className={dashboardStyle.extra}>CUOTA EXTRA</td>
                    <td className={dashboardStyle.extra}>
                      {cuota.formatted_expiration_date}
                    </td>
                    <td className={dashboardStyle.extra}>${cuota.pending}</td>
                  </tr>
                ))}
              {userDebtsWithStyles
                .filter((cuota) => cuota.is_extra === 2)
                .map((cuota) => (
                  <tr key={cuota.id} className="text-center">
                    <td className={dashboardStyle.extrap}>PRENDA EXTRA</td>
                    <td className={dashboardStyle.extrap}>
                      {cuota.formatted_expiration_date}
                    </td>
                    <td className={dashboardStyle.extrap}>${cuota.pending}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className={dashboardStyle.detailcontainer}>
            {/* Contenido Desplegable */}
            {isDetailVisible && (
              <div className={dashboardStyle.visibleDetail}>
                {cuotaInfo ? (
                  <>
                    <p>
                      {" "}
                      <span style={{ color: "#d54ab7" }}>
                        Cuota original:
                      </span>{" "}
                      ${cuotaInfo.original_price}
                    </p>
                    <p>
                      {" "}
                      <span style={{ color: "#d54ab7" }}>
                        Atraso por mora:
                      </span>{" "}
                      ${cuotaInfo.interest}
                    </p>
                    <p>
                      {" "}
                      <span style={{ color: "#d54ab7" }}>Total:</span> $
                      {cuotaInfo.original_price + cuotaInfo.interest}
                    </p>
                    <p> Monto redondeado a valores sin decimales</p>
                  </>
                ) : (
                  <>
                    <p>Cuota original: $ -</p>
                    <p>Intereses por financiamiento: $ -</p>
                  </>
                )}
              </div>
            )}
          </div>

          <section className={dashboardStyle.botonesContainers}>
            <div className={dashboardStyle.buttonscontainer}>
              {(contextData.customer.customer_type === 2 ||
                contextData.customer.customer_type === 3) && (
                <button
                  className={dashboardStyle.botones}
                  onClick={accountsSummary}
                >
                  RESUMEN DE CUENTA
                </button>
              )}
              <button
                onClick={toggleModalInfo}
                className={dashboardStyle.botones}
              >
                +INFO <span>{paymentMethod.toUpperCase()}</span>
              </button>
              {showModalInfoPago && (
                <ModalInfoPago toggleModalInfo={toggleModalInfo} />
              )}

              <button className={dashboardStyle.botones} onClick={toggleModal3}>
                INFORMAR PAGO
              </button>
              {isModal3Open && (
                <Modal3
                  onFormularioEnviado={handleFormularioEnviado}
                  toggleModal3={() => setIsModal3Open(!isModal3Open)}
                />
              )}
            </div>
          </section>
          <section className={dashboardStyle.survey}>
            <button className={dashboardStyle.ghostButton}>
              <a href="https://forms.gle/8bTDRNQw1tT3VkSy5">
                Nos interesa tu opinión
              </a>
              <img
                src={fantasmaopinion}
                alt="fantasmaopinion"
                className={dashboardStyle.ghost}
              />
            </button>
          </section>
        </div>
      )}
    </>
  );
}
export default Dashboard;
