const baseURL = process.env.REACT_APP_API_BASE_URL || "http://localhost:3005";
const v3_api_url = process.env.V3_API_URL ||   "http://localhost:8081";

//terms&&conditions
// cambiada a async
export const verifyTermsAccepted = async (leadId) => {
  try {
    const response = await fetch(
      `${baseURL}/api/verifyTermsAccepted/${leadId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error; // Re-lanza el error para manejarlo en el componente
  }
};

//funcion para el swal de terms&conditions
// cambiada a async

export const postTerms = async (datos) => {
  try {
    const response = await fetch(`${baseURL}/terms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datos),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error; // Re-lanza el error para manejarlo en el componente
  }
};

// registar usuario
export const registrarUsuario = async (datos) => {
  try {
    const response = await fetch(`${baseURL}/api/auth/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datos),
    });
    if (!response.ok) {
      throw new Error("La respuesta de la red no fue exitosa");
    }
    return await response.json();
  } catch (error) {
    console.error("Error al enviar la solicitud:", error);
    throw error; // Vuelve a lanzar el error para manejarlo en el componente
  }
};

//login
export const userLogin = async (datos) => {
  try {
    const response = await fetch(`${baseURL}/api/auth/signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datos),
    });

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error al iniciar sesión:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

//resumen de cuentas
export const getAllUsersSummary = async (lead_id) => {
  try {
    const response = await fetch(
      `${baseURL}/api/getAllUsersSummary/${lead_id}`
    );

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response;
  } catch (error) {
    console.error("Error al obtener el resumen de usuarios:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

// formulario pago facil

export const paymentPf = async (formData, customerId) => {
  try {
    const response = await fetch(`${baseURL}/api/paymentspf/${customerId}`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response;
  } catch (error) {
    console.error("Error al realizar el pago:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

//  formulario transferencia bancaria

export const paymentTF = async (formData, customerId) => {
  try {
    const response = await fetch(`${baseURL}/api/payments/${customerId}`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response;
  } catch (error) {
    console.error("Error al realizar el pago:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

// formulario tarjeta de credito

export const paymentTC = async (formData, customerId) => {
  try {
    const response = await fetch(`${baseURL}/api/paymentstc/${customerId}`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response;
  } catch (error) {
    console.error("Error al realizar el pago:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};
// formulario tarjeta de debito

export const paymentTD = async (formData, customerId) => {
  try {
    const response = await fetch(`${baseURL}/api/paymentstd/${customerId}`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response;
  } catch (error) {
    console.error("Error al realizar el pago:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};
// formulario deposito bancario
export const paymentDB = async (formData, customerId) => {
  try {
    const response = await fetch(`${baseURL}/api/paymentsdb/${customerId}`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response;
  } catch (error) {
    console.error("Error al realizar el pago:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};
// formulario efectivo
export const paymentEF = async (formData, customerId) => {
  try {
    const response = await fetch(`${baseURL}/api/paymentsef/${customerId}`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response;
  } catch (error) {
    console.error("Error al realizar el pago:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

// Guardar items
// cambiada a async

export const saveItems = async (customerId, selectedItems) => {
  try {
    const response = await fetch(`${baseURL}/api/saveItems/${customerId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedItems),
    });
    if (!response.ok) {
      throw new Error(response.status);
    }
    return await response.json();
  } catch (error) {
    throw new Error(`Error al guardar items: ${error}`);
  }
};

export const savePrendaExtra = async (customerId, selectedItems) => {
  try {
    const response = await fetch(
      `${baseURL}/api/savePrendaExtra/${customerId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedItems),
      }
    );
    if (!response.ok) {
      throw new Error(response.status);
    }
    return await response.json();
  } catch (error) {
    throw new Error(`Error al guardar items: ${error}`);
  }
};

// logout

export const logOut = async () => {
  try {
    const response = await fetch(`${baseURL}/api/logOut`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(
        `Error al cerrar sesión: ${response.status} ${response.statusText}`
      );
    }
    console.log("sesión cerrada");
    return await response.json();
  } catch (error) {
    console.error("Error al cerrar sesión:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

//getitems
// cambiada a async

export const getItems = async (leadId) => {
  try {
    const response = await fetch(`${baseURL}/api/getItems/${leadId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(
        `Error fetching items: ${response.status} ${response.statusText}`
      );
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};
// api.js

export const getPaymentInformation = async (customerId) => {
  try {
    const response = await fetch(
      `${baseURL}/api/getPaymentInformation/${customerId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(
        `Error fetching payment information: ${response.status} ${response.statusText}`
      );
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching payment information:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

export const getUserSizes = async (customerId, orderItemId) => {
  try {
    const response = await fetch(
      `${baseURL}/api/getUserSizes/${customerId}/${orderItemId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error(
        `Error fetching payment information: ${response.status} ${response.statusText}`
      );
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching payment information:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

// api.js
export const getAgentInfo = async (leadId) => {
  if (!leadId) {
    throw new Error("Lead ID is required");
  }
  try {
    const response = await fetch(`${baseURL}/api/getAgentInfo/${leadId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      throw new Error(
        `Error fetching agent info: ${response.status} ${response.statusText}`
      );
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching agent info:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

// paymentMethod
export const getPaymentMethodsByLeadId = async (leadId) => {
  try {
    const response = await fetch(`${baseURL}/api/getPaymentMethods/${leadId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(response.status);
    }
    return await response.json();
  } catch (error) {
    throw new Error(`Error en la solicitud de métodos de pago: ${error}`);
  }
};

// storePayments
export const storePayments = async (
  customerId,
  selectedPaymentSelectionId,
  leadId
) => {
  try {
    const response = await fetch(`${baseURL}/api/storePayments`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_id: customerId,
        payment_selection_id: selectedPaymentSelectionId,
        lead_id: leadId,
      }),
    });
    if (!response.ok) {
      throw new Error(response.status);
    }
    return await response.json();
  } catch (error) {
    throw new Error(`Error en la petición de almacenar pagos: ${error}`);
  }
};
//get feeInfo
export const getFeeInfo = async (id) => {
  try {
    const response = await fetch(`${baseURL}/api/getFeeInfo/${id}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Error en la solicitud");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// cambiada a async

export const getDesignExtra = async (leadId) => {
  const response = await fetch(`${baseURL}/api/getDesignExtra/${leadId}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("Error en la solicitud del costo extra");
  }
  return await response.json();
};

//usuarios con deudas
export const getUserDebts = async (userId) => {
  try {
    const response = await fetch(`${baseURL}/api/getAccountSummary/${userId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Error en la solicitud de deudas");
    }
    return await response.json();
  } catch (error) {
    console.error("Error al obtener las deudas del usuario:", error);
    throw error; // Lanza el error para manejarlo en el componente
  }
};
//get extra por cantidad
export const getExtraLowerQuantity = async (leadId) => {
  try {
    const response = await fetch(
      `${baseURL}/api/getExtraLowerQuantity/${leadId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        "Error en la solicitud del costo extra por menor cantidad de alumnos"
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error al obtener el costo extra:", error);
    throw error; // Lanza el error para manejarlo en el componente
  }
};

//Pregunta si el customer eligio alguna prenda:
export const hasCustomerItemsSelected = async (customer_id) => {
  try {
    const response = await fetch(
      `${baseURL}/api/hasCustomerItemSelected/${customer_id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        "Error en la solicitud del costo extra por menor cantidad de alumnos"
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error al obtener el costo extra:", error);
    throw error; // Lanza el error para manejarlo en el componente
  }
};

//Tabla de estado de cuenta (fetch)
export const fetchCustomersSummary = async (lead_id, page) => {
  try {
    const response = await fetch(
      `${baseURL}/api/getAllUsersSummary/${lead_id}?page=${page}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        "Error en la solicitud del costo extra por menor cantidad de alumnos"
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error al obtener el costo extra:", error);
    throw error;
  }
};

export const fetchFeesWithInterest = async (userDebts) => {
  try {
    const response = await fetch(`${baseURL}/api/getCompoundInterest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userDebts }),
    });

    if (!response.ok) {
      throw new Error("Error en la respuesta del servidor");
    }

    const data = await response.json();
    return data; // Asume que el servidor devuelve las cuotas actualizadas con intereses
  } catch (error) {
    throw error;
  }
};
// cargar talles

export const uploadSize = async (data) => {
  try {
    const response = await fetch(`${baseURL}/api/uploadSize`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Error al crear el talle");
    }

    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    throw new Error(`Error al enviar la petición: ${error.message}`);
  }
};

// llamar a todos los usuarios
export const prendasCadaUsuario = async (leadId, orderItemId) => {
  try {
    const response = await fetch(
      `${baseURL}/api/fetchAllUsersOrderItem/${leadId}/${orderItemId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching guide:", error);
    throw error;
  }
};

export const fetchAllUsers = async (leadId) => {
  try {
    const response = await fetch(
      `${baseURL}/api/getAllUsersFromOrder/${leadId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
    // Suponiendo que esperas un arreglo de usuarios en la respuesta
  } catch (error) {
    console.error("Error al obtener usuarios:", error);
    throw error;
  }
};
// getsize
export const getSizes = async (orderItemId) => {
  try {
    const response = await fetch(`${baseURL}/api/getSizes/${orderItemId}`);
    if (!response.ok) {
      throw new Error("Failed to fetch sizes");
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

// delete size
export const deleteSize = async (id) => {
  try {
    const response = await fetch(`${baseURL}/api/deleteSize/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to delete size");
    }

    return id; // Devuelve el ID eliminado si es necesario
  } catch (error) {
    throw error;
  }
};

// guias
export const getPrendasTutor = async (leadId) => {
  try {
    const response = await fetch(
      `${baseURL}/api/getAllItemsForTutor/${leadId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching items for tutor:", error);
    throw error;
  }
};

export const getAllCategories = async (customer_id) => {
  try {
    const response = await fetch(
      `${baseURL}/api/getAllCategories/${customer_id}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};

export const getGuide = async (orderItemId) => {
  try {
    const response = await fetch(`${baseURL}/api/getGuide/${orderItemId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching guide:", error);
    throw error;
  }
};

export const getSketchByOrderItemId = async (orderItemId) => {
  try {
    const response = await fetch(`${baseURL}/api/getSketch/${orderItemId}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching sketch:", error);
    throw error;
  }
};

export const approveSketch = async (orderItemId, customerId, sketchId) => {
  try {
    const response = await fetch(
      `${baseURL}/api/approveSketch/${orderItemId}/${sketchId}`,
      {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customerId }),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching sketch:", error);
    throw error;
  }
};

export const rejectSketch = async (formData, orderItemId, sketchId) => {
  try {
    const response = await fetch(
      `${baseURL}/api/rejectSketch/${orderItemId}/${sketchId}`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching sketch:", error);
    throw error;
  }
};

export const getAditionalDataOfItem = async (orderItemId) => {
  try {
    const response = await fetch(
      `${baseURL}/api/getAditionalDataOfItem/${orderItemId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching sketch:", error);
    throw error;
  }
};

export const saveAddress = async (formData) => {
  try {
    const response = await fetch(`${baseURL}/api/saveAddress`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error al enviar el formulario:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

export const getAllProvinces = async () => {
  try {
    const response = await fetch(`${baseURL}/api/getAllProvinces`);

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error al cargar provincias:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};
export const getDepartments = async (provinciaSeleccionada) => {
  return await fetch(`${baseURL}/api/getDepartments/${provinciaSeleccionada}`)
    .then((response) => response.json())
    .catch((error) => console.error("Error al cargar municipios:", error));
};

export const getDistricts = async (municipioSeleccionado) => {
  try {
    const response = await fetch(
      `${baseURL}/api/getDistricts/${municipioSeleccionado}`
    );

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error al cargar localidades:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

export const uploadOrderItemGuide = async (formData, orderItemId) => {
  try {
    const response = await fetch(
      `${baseURL}/api/uploadOrderItemGuide/${orderItemId}`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error uploading guide:", error);
    throw error;
  }
};

export const obtainGuideStatus = async (ordetItemId) => {
  try {
    const response = await fetch(
      `${baseURL}/api/getCurrentGuideInfo/${ordetItemId}`
    );

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error getting guide info status:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

export const getCurrentAddress = async (lead_id) => {
  try {
    const response = await fetch(`${baseURL}/api/getCurrentAddress/${lead_id}`);
    if (!response.ok) {
      throw new Error(`Error ${response.status} - ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error al obtener la dirección actual:", error);
    throw error;
  }
};

export const getSelectedDirection = async (lead_id) => {
  try {
    const response = await fetch(
      `${baseURL}/api/getSelectedDirection/${lead_id}`
    );
    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }
    return await response.json();
  } catch (error) {
    console.error("Error getting selected directions:", error);
    throw error;
  }
};

export const getProvinceById = async (id) => {
  try {
    const response = await fetch(`${baseURL}/api/getProvinceById/${id}`);

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error getting province:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

export const getDepartmentById = async (id) => {
  try {
    const response = await fetch(`${baseURL}/api/getDepartmentById/${id}`);

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error getting department:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

export const getDistrictById = async (id) => {
  try {
    const response = await fetch(`${baseURL}/api/getDistrictById/${id}`);

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error al obtener el distrito:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

export const getSizeById = async (id) => {
  try {
    const response = await fetch(`${baseURL}/api/getSizeById/${id}`);

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error al obtener la información del tamaño:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

export const editSize = async (data, id) => {
  try {
    const response = await fetch(`${baseURL}/api/editSize/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Error al editar el talle");
    }

    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    throw new Error(`Error al enviar la petición: ${error.message}`);
  }
};

export const completeSizes = (datos) => {
  return fetch(`${baseURL}/api/completeSizes`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(datos),
  });
};

export const sendPickUpAtOffice = (datos) => {
  return fetch(`${baseURL}/api/setPickUpAtOffice`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(datos),
  });
};

export const getOrderItemStatus = async (leadId, orderItemIds) => {
  try {
    const response = await fetch(
      `${baseURL}/api/getOrderItemStatus/${leadId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderItemIds: orderItemIds,
        }),
      }
    );
    if (!response.ok) {
      throw new Error("No se pudo obtener el estado del pedido");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error al obtener el estado del pedido:", error);
    throw error;
  }
};

export const getOrderItemsExtras = async (customer_id) => {
  try {
    const response = await fetch(
      `${baseURL}/api/getOrderItemsExtras/${customer_id}`
    );

    if (!response.ok) {
      throw new Error(
        `Error en la solicitud: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error al obtener los extras de los order_items:", error);
    throw error; // Propagar el error para que el llamador pueda manejarlo
  }
};

// fecha de expiracion de beneficios por metodos de pago

export const getExpirationDate = async (orderId) => {
  try {
    const response = await fetch(
      `${baseURL}/api/retornarExpiracionDeBeneficios/${orderId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error fetching expiration date: ${response.status} ${response.statusText}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching expiration date:", error);
    throw error;
  }
};

// rifas

export const validarRifa = async (codigo, number) => {

  const formData = new FormData();
  formData.append("code", codigo);
  formData.append("number", number);

  try {
    const response = await fetch(
      `${v3_api_url}/api/public/raffle/check-existance`,
      {
        method: "POST",
        body: formData, 
      }
    );

    const data = await response.json();

    if (!response.ok || data.status !== 0) {
      throw new Error(data.message || "Código y/o número de rifa incorrecto");
    }

    return data; // Devuelve la respuesta si es exitosa
  } catch (error) {
    throw error; // Lanza el error para manejarlo en el componente
  }
};

// campos de la rifa

export const fillRaffleData = async (code, number, bodyData) => {
  const url = `${baseURL}/api/raffles/fillData/${code}/${number}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData), // Convert the form data to JSON format
    });

    if (!response.ok) {
      throw new Error("Error al enviar los datos. Verifique la información.");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error en la solicitud POST:", error);
    throw error;
  }
};

export const getAllPrizes = async () => {
  try {
    const response = await fetch(
      `${baseURL}/api/raffles/getPrizeImages`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};
