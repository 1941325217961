import "./App.css";
import Register from "./components/Register";
import Login from "./components/Login";
import ModalConfirmarItems from "./components/ModalConfirmarItems.jsx";
import Dashboard from "./components/Dashboard";
import LoginEnriched from "./components/LoginEnriched";
import AceptarTerminos from "./components/Terms&Conditions";
import WaitingTerms from "./components/WaitingTerms";
import LayOut from "./components/LayOut";
import FAQ from "./components/FAQ";
import LogOut from "./components/LogOut";
import Rifas from "./components/Rifas";
import Entregas from "./components/Entregas";
import Prendas from "./components/Prendas";
import InformPayment from "./components/MetodosDePago/InformPayment.jsx";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import CargaCorrecta from "./components/CargaCorrecta.jsx";
import AccountsSummary from "./components/AccountsSummary.jsx";
import CargarTalles from "./components/CargarTalle.jsx";
import Modal1 from "./components/Modal1";
import ConfirmarTalle from "./components/ConfirmarTalles.jsx";
import EditarTalle from "./components/EditarTalle.jsx";
import AlertPropio from "./components/AlertPropio.jsx"

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/signin/enriched/:accessCode/code"
            element={<LoginEnriched />}
          />
          <Route path="/terminos" element={<AceptarTerminos />} />
          <Route path="/registro" element={<Register />} />
          <Route path="/esperando" element={<WaitingTerms />} />
          <Route path="/Modal1" element={<Modal1 />} />
          <Route path="/correcto" element={<CargaCorrecta />} />
          <Route path="/talles" element={<CargarTalles />} />
          <Route path="/verificar" element={<ConfirmarTalle />} />
          <Route path="/AlertPropio" element={<AlertPropio />} />
          <Route path="/ModalConfirmarItems" element={<ModalConfirmarItems />} />


          <Route path="/mcb" element={<LayOut />}>
            <Route index element={<Dashboard />} />
            <Route path="accountsSummary" element={<AccountsSummary />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="logOut" element={<LogOut />} />
            {/* <Route path="rifas" element={<Rifas />} /> */}
            <Route path="entregas" element={<Entregas />} />
            <Route path="prendas" element={<Prendas />} />
            <Route path="informar" element={<InformPayment />} />
            <Route path="correcto" element={<CargaCorrecta />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
