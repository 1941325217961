import React, { useEffect, useState } from "react";
import dashboardStyle from "../styles/Dashboard.module.css";
import Danger from "../images/Danger.png";
import frame from "../images/Frame.png";
import { useDataContext } from "../contexts/AuthContext";
import { useDebtsContext } from "../contexts/FeeContext";
import {
  getPaymentMethodsByLeadId,
  storePayments,
  getExpirationDate,
  getPaymentInformation,
} from "../services/api";
import "../styles/estilos/modaldos.css";
import logoPng from "../images/Frame.png";
import Swal from "sweetalert2";

const Modal2 = ({
  onAcceptClick,
  onBackClick,
  setPaymentInformation,
  setPaymentMethod,
  setTotal,
  setPaid,
  vengodelModal1,
  handleAtrasClick,
  vengodelModal2,
}) => {
  const { contextData } = useDataContext();
  const { setUserDebts } = useDebtsContext();
  const [paymentMethods, setPaymentMethods] = useState({});
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [selectedInstallments, setSelectedInstallments] = useState({});
  const [selectedMethodIndex, setSelectedMethodIndex] = useState(null);
  const [expandedMethods, setExpandedMethods] = useState({});
  const [loading, setLoading] = useState(true);
  const [expirationDate, setExpirationDate] = useState(null);
  const [isCloseButtonEnabled, setIsCloseButtonEnabled] = useState(false);

  useEffect(() => {
    if (!contextData || !contextData.customer || !contextData.customer.id) {
      return;
    }

    getPaymentInformation(contextData.customer.id)
      .then((data) => {
        console.log("Datos de pago:", data);
        setPaymentMethod(data.payment_method);
        // Habilitar el botón de cierre si payment_method tiene un valor
        if (data.payment_method) {
          setIsCloseButtonEnabled(true);
        } else {
          setIsCloseButtonEnabled(false);
        }
      })
      .catch((error) => {
        console.error("Error al obtener la información de pago:", error);
      });
  }, [contextData.customer.id]);

  useEffect(() => {
    if (
      !contextData ||
      !contextData.customer ||
      !contextData.customer.lead_id
    ) {
      return;
    }

    const fetchPaymentMethods = async () => {
      try {
        const data = await getPaymentMethodsByLeadId(
          contextData.customer.lead_id
        );
        console.log("Respuesta del servidor (paymentMethods):", data);
        if (typeof data === "object" && !Array.isArray(data)) {
          setPaymentMethods(data);
          setLoading(false);
        } else {
          console.log(
            "La respuesta del servidor no contiene un objeto de métodos de pago."
          );
        }
      } catch (error) {
        console.log(
          `Error en la solicitud de métodos de pago: ${error.message}`
        );
      }
    };
    fetchPaymentMethods();
  }, [contextData.customer.lead_id]);

  useEffect(() => {
    if (
      !contextData ||
      !contextData.customer ||
      !contextData.customer.order_id
    ) {
      return;
    }

    const fetchExpirationDate = async () => {
      try {
        const data = await getExpirationDate(contextData.customer.order_id);
        console.log("Fecha de expiración recibida:", data);
        // Almacenar la fecha de expiración en su formato original
         setExpirationDate(data.expirationDate);
       
      } catch (error) {
        console.log(`Error fetching expiration date: ${error.message}`);
      }
    };
    fetchExpirationDate();
  }, [contextData.customer.order_id]);

  // Función para verificar si la fecha de expiración ha pasado
  const isExpired = (dateStr) => {
    if (!dateStr) return false; // Si no hay fecha, no está expirada

    // Reemplazar el espacio con 'T' para convertir a formato ISO 8601
    const isoDateStr = dateStr.replace(" ", "T");
    const expDate = new Date(isoDateStr);
    const today = new Date();

    // Establecer las horas a 0 para comparar solo fechas
    today.setHours(0, 0, 0, 0);
    expDate.setHours(0, 0, 0, 0);

    return expDate < today;
  };

  // Función para formatear la fecha al formato dd/mm/yy
  const formatDate = (dateStr) => {
    if (!dateStr) return "Fecha no disponible";

    // Reemplazar el espacio con 'T' para convertir a formato ISO 8601
    const isoDateStr = dateStr.replace(" ", "T");
    const date = new Date(isoDateStr);

    if (isNaN(date)) return "Fecha inválida";

    let dia = date.getDate();
    let mes = date.getMonth() + 1; // Los meses van de 0 a 11
    let año = date.getFullYear().toString().slice(-2); // Obtener los últimos dos dígitos

    // Asegurarse de que el día y el mes tengan dos dígitos
    dia = dia < 10 ? "0" + dia : dia;
    mes = mes < 10 ? "0" + mes : mes;

    return `${dia}/${mes}/${año}`;
  };

  const handleMethodChange = (method, index) => {
    setSelectedMethod(method.payment_selection_id);
    setSelectedInstallments({
      [method.paymentMethod]: method.cuotas,
    });
    setSelectedMethodIndex(index);
  };

  const handleInstallmentsChange = (method, cuotas) => {
    setSelectedInstallments({
      ...selectedInstallments,
      [method.paymentMethod]: cuotas,
    });
  };

  const handleToggleExpansion = (index) => {
    setExpandedMethods({
      ...expandedMethods,
      [index]: !expandedMethods[index],
    });
  };

  const handleSingleInstallmentSelect = (method) => {
    setSelectedMethod(method.payment_selection_id);
    setSelectedInstallments({
      [method.paymentMethod]: method.cuotas,
    });
  };

  const handleSecondModalSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await storePayments(
        contextData.customer.id,
        selectedMethod,
        contextData.customer.lead_id
      );
      console.log("Respuesta de la petición:", data);
      setUserDebts(data.accountSummary);
      setPaymentInformation(data.paymentInformation);
      setPaymentMethod(data.paymentInformation.payment_method);
      setTotal(data.paymentInformation.total);
      setPaid(data.paymentInformation.paid);
      vengodelModal2(false);
      onAcceptClick();
    } catch (error) {
      console.error(
        `Error en la petición de almacenar pagos: ${error.message}`
      );
      Swal.fire({
        icon: "warning",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atencion",
        text: "Por favor seleccione un metodo de pago.",
      });
    }
  };

  return (
    <>
      {loading ? (
        <div className="contcargando">
          <img src={logoPng} alt="littleBlueLogo" className="imgcargando" />
          <h2 className="text-muted">CARGANDO...</h2>
          <div className="loader"></div>
        </div>
      ) : (
        <div className={dashboardStyle.modalBackground}>
          <div className={dashboardStyle.modal}>
            <img className={dashboardStyle.danger} src={Danger} alt="Danger" />
            <form onSubmit={handleSecondModalSubmit}>
              <div>
                <img className="frame-image" src={frame} alt="frame" />
              </div>
              <div className="containerm2 mt-3 d-flex flex-column">
                <h3 className="mb-3 titulo3">
                  Métodos <span style={{ color: "#D54AB7" }}>de</span> Pago
                </h3>
                <div>
                  <h6 className="text-muted mb-4 subtitle2">
                    Es importante que elijas la manera correcta, para que
                    podamos calcular bien el importe
                  </h6>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  {Object.keys(paymentMethods).map((methodName, index) => {
                    const methodGroup = paymentMethods[methodName];
                    const hasMultipleInstallments = methodGroup.some(
                      (method) => method.cuotas > 1
                    );
                    const hasSingleInstallment =
                      methodGroup.length === 1 && methodGroup[0].cuotas === 1;

                    return (
                      <div key={index} className="mb-3">
                        <div>
                          <div className="card-body">
                            <label
                              className="titulo mb-4"
                              onClick={() => handleToggleExpansion(index)}
                            >
                              {hasSingleInstallment && (
                                <input
                                  type="radio"
                                  id={`radio-${index}`}
                                  name="paymentMethod"
                                  className="form-check-input"
                                  checked={
                                    selectedMethod ===
                                    methodGroup[0].payment_selection_id
                                  }
                                  onChange={() =>
                                    handleSingleInstallmentSelect(
                                      methodGroup[0]
                                    )
                                  }
                                />
                              )}
                              {methodName}
                              {hasSingleInstallment &&
                                methodGroup[0].adicional !== "none" &&  methodGroup[0].porcentaje > 0 && (
                                  <span
                                    className={`ms-2 ${
                                      methodGroup[0].adicional === "discount"
                                        ? "discount"
                                        : ""
                                    }`}
                                  >
                                    {methodGroup[0].adicional === "discount" &&
                                      (isExpired(expirationDate) ? (
                                        " "
                                      ) : (
                                        ` Descuento: ${methodGroup[0].porcentaje}% - Vence: ${formatDate(
                                          expirationDate
                                        )}`
                                      ))}
                                     {methodGroup[0].adicional ===
                                      "surcharge" &&
                                      ` (Sobrecargo: ${methodGroup[0].porcentaje}%)`}  
                                     
                                     
                                  </span>
                                )}
                              {!hasSingleInstallment && (
                                <span
                                  onClick={() => handleToggleExpansion(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {expandedMethods[index] ? (
                                    <i className="bi bi-caret-up-fill felchitaa"></i>
                                  ) : (
                                    <i className="bi bi-caret-down-fill felchitaa"></i>
                                  )}
                                </span>
                              )}
                            </label>
                            {expandedMethods[index] &&
                              !hasSingleInstallment && (
                                <div className="form-check">
                                  {methodGroup.map((method, innerIndex) => (
                                    <div
                                      key={innerIndex}
                                      className="form-check"
                                    >
                                      <input
                                        type="radio"
                                        id={`radio-${index}-${innerIndex}`}
                                        name="paymentMethod"
                                        className="form-check-input"
                                        checked={
                                          selectedMethod ===
                                            method.payment_selection_id &&
                                          selectedInstallments[
                                            method.paymentMethod
                                          ] === method.cuotas
                                        }
                                        onChange={() =>
                                          handleMethodChange(method, index)
                                        }
                                      />
                                      <label
                                        htmlFor={`radio-${index}-${innerIndex}`}
                                        className="form-check-label titulo"
                                      >
                                        {method.paymentMethod} {method.cuotas}{" "}
                                        cuotas
                                        {method.adicional === "discount" && (
                                          <span className="discount">
                                            {" "}
                                            Descuento: {method.porcentaje}%
                                            -Vence: {expirationDate}
                                          </span>
                                        )}
                                        {method.adicional === "surcharge" &&
                                          ` (Recargo: ${method.porcentaje}%)`}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="row mt-3 containerBoton d-flex flex-row flex-wrap justify-content-center">
                <div className="col-md-6 d-flex justify-content-center ">
                  <div>
                    <button
                      className="btn btn-primary butonModal2a"
                      onClick={onBackClick}
                    >
                      ATRÁS
                    </button>
                    <button
                      className="btn btn-primary butonModal2"
                      type="submit"
                    >
                      ACEPTAR
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal2;
